<template>
  <div class="position-relative">
    <b-row>
      <b-col md="6">
        <!-- <span v-if="checkPermission('all_access')"> -->
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" v-b-toggle.sidebar-addNewCategory>
          Add New Category
        </b-button>
        <!-- </span> -->
      </b-col>

      <b-col md="6">
        <b-row>
          <b-col md="8">
            <b-form-group   label-cols-sm="2" label-align-sm="right" label-size="md" label-for="filterInput">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group   label-cols-sm="4" label-align-sm="right" label-size="sm"
              label-for="perPageInput">
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                @change="savePerPage(perPage)" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-table
  v-if="category.length > 0"
  :items="category"
  :fields="fields"
  responsive="sm"
  :sticky-header="false"
  striped
  small
  id="role-table"
  :per-page="perPage"
  :current-page="currentPage"
  :filter="filter"
  :filter-included-fields="filterOn"
  @filtered="onFiltered"
>
      <template #cell(name)="row">
        <span>{{ row.item.name }}</span>
      </template>

      <template #cell(role)="row">
        <span>{{ row.item.roles[0].display_name }}</span>
      </template>

      <template #cell(permissions)="row">
        <p v-for="permission in row.item.permissions" :key="permission.id">
          {{ permission.name }}
        </p>
      </template>

      <template #cell(action)="row">
        <!-- <span v-if="checkPermission('all_access')"> -->
        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template v-slot:button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
          </template>
          <b-dropdown-item v-b-toggle.sidebar-editcategory @click="sendCategoryEditProps(row.item)">
            <feather-icon icon="Edit2Icon" class="mr-50" />
            <span>Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deletecategory(row.item.id)">
            <feather-icon icon="TrashIcon" class="mr-50" />
            <span>Delete</span>
          </b-dropdown-item>
        </b-dropdown>
        <!-- </span> -->
      </template>
    </b-table>
    <div v-else class="text-center mt-4">{{ emptyCategoryMessage }}</div>
    <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" first-number last-number
      class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
      <template #prev-text>
        <feather-icon icon="ChevronLeftIcon" size="18" />
      </template>
      <template #next-text>
        <feather-icon icon="ChevronRightIcon" size="18" />
      </template>
    </b-pagination>

    <add-new-category @refresh="getAllcategory" />
    <edit-category :editCategory="editCategory" @refresh="getAllcategory" />

    <b-overlay :show="loader" no-wrap spinner-variant="primary"></b-overlay>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  VBToggle,
  BOverlay,
  BRow,
  BCol,
  BPagination,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BBadge,
  VBTooltip
} from "bootstrap-vue";
import axios from "axios";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddNewCategory from "./AddNewCategory.vue";
import EditCategory from "./EditCategory.vue";
import { json } from "body-parser";
import User from '../../../Helpers/User'

export default {
  components: {
    BTable,
    BButton,
    BRow,
    BCol,
    BPagination,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BBadge,
    ToastificationContent,
    AddNewCategory,
    EditCategory,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      perPage: "25",
      pageOptions: [25, 50, 100, 200],
      emptyCategoryMessage: "No Data Found.",
      currentPage: 1,
      totalRows: 1,
      filter: null,
      filterOn: [],
      fields: ["name", "display_name", { key: "action", label: "Action", tdClass: 'actionClass' }],
      category: [],
      loader: false,
      roleData: {},
      editCategory: {}
    };
  },
  mounted() {
    this.perPage = this.getPerPage() ? this.getPerPage() : "25";
  },
  created() {
    this.getAllcategory();
  },

  methods: {
    checkPermission(permission) {

      return User.checkPermission(permission)
    },

    sendCategoryEditProps(param) {
      this.editCategory = param
    },

    getAllcategory() {
      this.loader = true;
      axios
        .get(process.env.VUE_APP_API + "/category")
        .then((res) => {
          this.loader = false;
          this.category = res["data"];
        })
        .catch((error) => {
          this.loader = false;
          console.log(error);
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deletecategory(categoryId) {
      if (categoryId) {
        this.loader = true
        axios({
          method: 'delete',
          url: process.env.VUE_APP_API + '/category/category',
          data: {
            'categoryId': categoryId,
          }
        })
          .then((res) => {
            if (res.data.success) {
              this.getAllcategory()
              this.loader = false
              this.$toast
                ({
                  component: ToastificationContent,
                  props: {
                    title: 'Category Deleted',
                    icon: 'AlertOctagonIcon',
                    text: '',
                    variant: 'danger',
                  },
                },
                  {
                    position: 'top-right'
                  })
            }
            else {
              this.loader = false
              this.$toast
                ({
                  component: ToastificationContent,
                  props: {
                    title: 'Failed',
                    icon: 'AlertOctagonIcon',
                    text: '',
                    variant: 'danger',
                  },
                },
                  {
                    position: 'top-left'
                  })
            }
          })
          .catch((error) => {
            this.loader = false
            console.log(error);
          });

      }
    },
    rolepermission(roleId) {

    },
  },

  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD , hh:mm:ss");
      }
    },
  },
  computed: {
    rows() {
      return this.category.length;
    },
  }
};
</script>
  
<style>
.actionClass {
  max-width: 300px;
}
</style>
